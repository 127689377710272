<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
        <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="5" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
          <!-- <b-button variant="outline-primary" class="d-flex d-md-inline-block mt-0 w-md-auto w-100 mb-6"  v-b-toggle.sidebar-right>Filtrele</b-button> -->
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Mülkler Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
        >
          <template v-slot:item.fiyat="{ item }">
            <p class="text-dark">{{ item.fiyat }} ₺</p>
          </template>
         <template v-slot:item.tip ="{item}">
          <b-badge variant="success" v-if="item.tip === 1">Onaylandı</b-badge>
          <b-badge variant="warning" v-else-if="item.tip === 2">Bekliyor</b-badge>
              <b-badge  variant="danger" v-if="item.tip === 3" @click="openModal = !openModal" style="cursor: pointer;">Onaylanmadı <b-icon  icon="exclamation-circle" class="bg-info ml-1"></b-icon></b-badge>
          </template>
         
          <template v-slot:item.action="{ item }" class="p-0">
            <div class="d-flex justify-content-end">
              <router-link
                :to="`/mulkler/mulk-detay/${item.id}/`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <button
                  class="btn"
                  :href="href"
                  @click="navigate"
                  ><i class="flaticon2-arrow text-dark p-0"></i
                ></button>
              </router-link>
              <router-link
                :to="`properties/detail`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <button
                  class="btn"
                  :href="href"
                  @click="navigate"
                  ><i class="flaticon2-trash text-danger p-0"></i
                ></button>
              </router-link>
              
            </div>
          </template>
        </List>
        <!-- Create and Actions Popups -->
      </div>
     <!-- <div class="notes-sidebar top-full">
          <b-sidebar id="sidebar-right" right>
            <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
              <div class="card-header">
                FİLTRELE
                
              </div>
              <b-tabs content-class="mt-3">
              <b-tab content-class="tab-content" title="Filtre" active>
                  <div class="form-group">
                      <label for="sahip">Sahibi</label>
                      <select name="" id="sahip" class="form-control" >
                          <option value="" selected>Seçiniz</option>
                          <option value="">Şekerbank A.Ş</option>
                      </select>
                  </div>
                  <div class="form-group">
                      <label for="izle">İzlenecek</label>
                      <select name="" id="izle" class="form-control" >
                          <option value="" selected>Seçiniz</option>
                          <option value="">Evet</option>
                          <option value="">Hayır</option>
                      </select>
                  </div>
                  <div class="form-group">
                      <label for="durum">Durum</label>
                      <select name="" id="durum" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select>
                      
                  </div>
                  <div class="form-group">
                      <label for="il">İl</label>
                      <select name="" id="il" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select>
                      
                  </div>
                  <div class="form-group">
                      <label for="ilce">İlçe</label>
                      <select name="" id="ilce" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">1</option>
                          <option value="">2</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat">Anahtar Durumu</label>
                      <select name="" id="anahat" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">Var</option>
                          <option value="">Yok</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat" class="form-label">İşgalci Durumu</label>
                      <select name="" id="anahat" class="form-control">
                          <option value="" selected>Seçiniz</option>
                          <option value="">Var</option>
                          <option value="">Yok</option>
                          <option value="">3</option>
                      </select> 
                  </div>
                  <div class="form-group">
                      <label for="anahat">Ada</label>
                      <input type="text" class="form-control">
                  </div>
                  <div class="form-group">
                      <label for="anahat">Parsel</label>
                      <input type="text" class="form-control">
                  </div>
                  <div class="row">
                      <div class="col-md-6 col-12 mt-4">
                          <b-button variant="light" class="w-100" >
                              Temizle
                          </b-button>
                      </div>
                      <div class="col-md-6 col-12 mt-4">
                          <b-button variant="light" class="w-100">
                              Uygula
                          </b-button>
                      </div>
                      <div class="col-12 mt-4">
                          <b-button variant="light" class="w-100">
                              Bu Filtreyi Kaydet
                          </b-button>
                      </div>
                  </div>
              </b-tab>
              <b-tab title="Hızlı Ara">
                  <div
                  class="card mt-4 dveb-card-white"
                  style="border-radius: 0 !important"
                >
                  <div>
                    <h4> Filtre Örnek </h4>
                    <hr>
                    <div class="row ml-auto">
                      <b-button variant="success" class="mr-4" >
                        Uygula
                      </b-button>
                      <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                        Sil
                      </b-button>
                    </div>
                  </div>
              </div>
              </b-tab>
            </b-tabs>
            </div>
          </b-sidebar>
      </div>
      <DeleteOperation
      />
      <div>
-->
      <b-modal v-model="openFullTextModal" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal>
      <b-modal v-model="openModal" title="Onaylanmama Nedeni">
        <p class="my-4">Şu sebeplerden dolayı onaylanmamıştır;<p>Fiyat Pahalı.</p></p>
        <template #modal-footer>
        <button @click="openModal=false" class="btn btn-primary">Tamam</button>
        </template>
      </b-modal>
    </div>
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text {
    span {
      background-color: $dveb-divider;
      padding: 0.2rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
  </style>
  
  <script>
  export default {
    name: "customerList",
    
    data() {
      return {
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames : ["Hazırlık","Satış","Devir"],
        header: [
          { text: "", value: "id", size: "50px" },
          { text: "İlan Adı", sortable:true,value: "ilanadi" },
          { text: "Müşteri Adı", sortable:true,value: "musteriadi" },
          { text: "İl", value: "il" },
          { text: "İlçe", value: "ilce" },
          { text: "Fiyat", sortable:true, value: "fiyat" },
          { text: "Tahmini Gün", moreText:true, value: "tahminigun" },
          { text: "Onaylandı Mı?", value: "tip" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList:[],
        list_length:5,
        openFullTextModal:false,
        openModal: false,
      };
    },
    components:{
      Moretext : () =>import("@/components/global/Moretext")
    },
    created() {
      this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      this.getList();
  
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
          {
            id: 1,
            ilanadi: "Avize Tamiri",
            musteriadi: "Ahmet Berke Aydın",
            il: "İstanbul",
            ilce:"Beylikdüzü",
            mahalle:"Ahmet Yesevi Mahallesi",
            fiyat:450,
            tahminigun:1,
            tip:1,
          },
          {
            id: 2,
            ilanadi: "Boru Tamiri",
            musteriadi: "Melisa Eldem",
            il: "İstanbul",
            ilce:"Ataşehir",
            mahalle:"Esatpaşa Mahallesi",
            fiyat:200,
            tahminigun:5,
            tip:2,
          },
          {
            id: 3,
            ilanadi: "Özel Koruma",
            musteriadi: "Rasim Karadeniz",
            il: "İstanbul",
            ilce:"Sultanbeyli",
            mahalle:"Ahmet Yesevi Mahallesi",
            fiyat:200,
            tahminigun:365,
            tip:3,
          },
          
        ];
        this.totalItems = this.items.length;
  
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items?.length/this.list_length),
          total_items: this.items.length,
          list_length: this.list_length
        };
        this.loading = false;
        if (this.search) {
          const searchValue = this.search;
          const foundItems = this.items.filter((item) => {
            for (const prop in item) {
              if (item[prop].toString().toLowerCase().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          this.items = foundItems;
        }
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      openDeleteHandler(key) {
        this.$bvModal.show('modal-deleteOperation');
        let index = this.notes.indexOf(key)
        this.notes.splice(index,1)
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length:function(){
        this.getList()
      },
      $route:function(){
        this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      }
      
  
    },
   
  };
  </script>
  